

#scroll-text-container {
    overflow: hidden;
    white-space: nowrap;
}


#scroll-text {
    white-space: nowrap;
    text-align: left;
}


@keyframes text-scroll-animation-normal {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-150%);
    }
}


@keyframes text-scroll-animation-long {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-400%);
    }
}


@keyframes text-scroll-animation-very-long {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-600%);
    }
}


/*  SCROLL BAR  */
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eeeeee;
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4a4a49;
    box-shadow: inset 0 0 5px #1d1d1b;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3966b8;
}

